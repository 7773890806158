import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { game } from './game.reducer';
import { sockets } from './socket.reducer';
import { sound } from './sound.reducer';
import { modal } from './modal.reducer';

const rootReducer = combineReducers({
    alert,
    sockets,
    sound,
    modal,
    game,
});

export default rootReducer;
