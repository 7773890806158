import { gameConstants} from '../_constants';

const initialState = {
  setting : {}
}

export function game(state = initialState , action) {
  switch (action.type) {
    case gameConstants.FETCH_CONFIG:
      return {
        ...state,
        setting: action.data,
      };
    default:
      return state
  }
}
