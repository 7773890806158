import React from "react";
import { connect } from "react-redux";
import ReactHintFactory from "react-hint";
import "react-hint/css/index.css";
import { gameActions, modalActions } from "../_actions";
import { soundPlayers } from "../_helpers";
import { SETTING } from "../_constants";
import { HeaderBar } from "../_components";
import $ from "jquery";
require("../_assets/css/score/ui_game/ladder.css");
var moment = require('moment');
const ReactHint = ReactHintFactory(React);

class GamePage extends React.Component {
  state = {
    isBeingAnimation: false,
    lastPlayedRound : 0,
    isPlayingSound: false,
    menuFlag: 0,
  };

  constructor() {
    super();
    this.startClickTimer();
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.sockets.isEnded && !this.state.isBeingAnimation) {
      this.startResultAnimation();
    }
  }

  handleClickMenu(flag) {
    this.setState({ menuFlag: flag });
  }

  startResultAnimation() {
    let self = this;
    const { sockets } = this.props;
    let { currentResult, currentWinResult, isEnded } = sockets;
    if(this.state.lastPlayedRound === currentWinResult['pick_num2']) return;
    this.setState({ isBeingAnimation: true });
    this.setState({lastPlayedRound: currentWinResult['pick_num2']});
    console.log("currentWinResult", currentWinResult);
    var content = $(".gmContent");
    var pickinfo = $("#pickinfo");
    var playResult = content.find(".playResult");
    var lineBox = content.find(".lineBox");
    lineBox.html("");
    var ladderLine = '<em class="line"></em>';
    var lis = "<li></li><li></li><li></li><li></li>";
    var ulClass = "s4";
    var startIcon = content.find(".start_l");
    var endIcon = content.find(".end_l");
    if (currentWinResult.gubun == 1 || currentWinResult.gubun == 3) {
      lis = "<li></li><li></li><li></li>";
      ulClass = "s3";
    }
    
    if (currentWinResult.lr === "RIGHT") {
      startIcon = content.find(".start_r");
    }
    if (currentWinResult.oe === "EVEN") {
      endIcon = content.find(".end_r");
    }
    pickinfo.hide();
    content.find(".pPick").find("ul").html("");
    content.find(".playBox ul").removeClass().addClass(ulClass).html(lis);
    content.find(".bepickLogo").hide();
    var sType = SETTING[currentWinResult["lr"].substring(0, 1) + currentWinResult["line"]];
    new Promise(function (resolve, reject) {
      startIcon.addClass("on");
      setTimeout(function () {
        resolve();
      }, 500);
    }).then(function () {
      lineBox
        .append(ladderLine).find("em:eq(0)").css(sType[0].pos).animate(sType[0].size, sType[0].spd).promise()
        .then(function () {
          return lineBox.append(ladderLine).find("em:eq(1)").css(sType[1].pos).animate(sType[1].size, sType[1].spd).promise();
        })
        .then(function () {
          return lineBox.append(ladderLine).find("em:eq(2)").css(sType[2].pos).animate(sType[2].size, sType[2].spd).promise();
        })
        .then(function () {
          return lineBox.append(ladderLine).find("em:eq(3)").css(sType[3].pos).animate(sType[3].size, sType[3].spd).promise();
        })
        .then(function () {
          return lineBox.append(ladderLine).find("em:eq(4)").css(sType[4].pos).animate(sType[4].size, sType[4].spd).promise();
        })
        .then(function () {
          return lineBox.append(ladderLine).find("em:eq(5)").css(sType[5].pos).animate(sType[5].size, sType[5].spd).promise();
        })
        .then(function () {
          return lineBox.append(ladderLine).find("em:eq(6)").css(sType[6].pos).animate(sType[6].size, sType[6].spd).promise();
        })
        .then(function () {
          if (typeof sType[7] == "object") {
            return lineBox.append(ladderLine).find("em:eq(7)").css(sType[7].pos).animate(sType[7].size, sType[7].spd).promise();
          } else {
            return console.log("null");
          }
        })
        .then(function () {
          if (typeof sType[8] == "object") {
            return lineBox.append(ladderLine).find("em:eq(8)").css(sType[8].pos).animate(sType[8].size, sType[8].spd)              .promise();
          } else {
            return console.log("null");
          }
        })
        .then(function () {
          endIcon.addClass("on");
          setTimeout(function () {
             content.find(".progressBar, .timeBox").hide();
             self.resultBox(currentWinResult);
          }, 1000);
        });
    });
  }

  resultBox(response) {
    let self = this;
    self.setState({ isPlayingSound: false });
    var content = $(".gmContent");
    var HTML = "<h2>" + response.pick_num2 + "회차 게임결과</h2>";
    HTML += "<p>";
    HTML += '<span class="ricon r1_' + response.lr + '"></span>' + "\n";
    HTML += '<span class="ricon r2_' + response.line + '"></span>' + "\n";
    HTML += '<span class="ricon r3_' + response.oe + '"></span>' + "\n";
    HTML += "</p>" + "\n";
    content.find(".gameResultBox").html(HTML).show();
    setTimeout(function () {
      content.find(".lineBox, .playBox ul").html("");
      $("#waiting_board").show();
      content.find(".bepickLogo, .progressBar, .timeBox").show();
      content.find(".gameResultBox, .playResult").hide().html("");
      content.find(".pl_icon").removeClass("on");
      self.setState({ isBeingAnimation: false });
      self.updateGame(response);
    }, 5000);
  }

  updateGame(response) {
    var content = $(".gmContent");
    var tResult = content.find(".tResult");
    var tmp_t = tResult.find("li:eq(0)").clone();
    var sdate =
      response.pick_date.substr(0, 4) +
      "." +
      response.pick_date.substr(4, 2) +
      "." +
      response.pick_date.substr(6, 2);
    tmp_t.find("h3").text(sdate + " - " + response.pick_num2);
    tmp_t.find(".ricon").removeClass().addClass("ricon");
    tmp_t
      .find(".resultBox")
      .find("span:eq(0)")
      .addClass("r1_" + response.lr);
    tmp_t
      .find(".resultBox")
      .find("span:eq(1)")
      .addClass("r2_" + response.line);
    tmp_t
      .find(".resultBox")
      .find("span:eq(2)")
      .addClass("r3_" + response.oe);
    tResult.find("ul").prepend(tmp_t);
  }

  startClickTimer() {
    var self = this;
    let timerId = setInterval(function () {
      const { dispatch, sockets } = self.props;
      if (sockets.currentResult.secondsLeft === undefined) return;
      try {
        dispatch(gameActions.downLeftTime());
        if (sockets.currentResult.secondsLeft === 4 &&!self.state.isPlayingSound) {
          soundPlayers.readySoundPlayer.play();
          self.setState({ isPlayingSound: true });
        }
        if (sockets.currentResult.secondsLeft < 0) {
          $("#waiting_board").hide();
          //$("#waiting_score_board").show();
        }
        if (sockets.currentResult.secondsLeft < -25) {
          alert("회차정보 오류로 인하여 결과값을 가져 올 수 없습니다.");
          clearInterval(timerId);
          try {
            window.parent.location.reload();
          } catch (e) {
            window.location.reload();
          }
        }
      } catch (e) {}
    }, 1000);
  }

  getDateTimeStr() {
    const { modal, sockets } = this.props;
    let { hourDiff, clockDiff } = sockets;
    if (clockDiff === undefined) clockDiff = 0;
    hourDiff = 9;
    let nowTime = moment.utc();
    let strDate = "";
    try {
      nowTime.add(hourDiff, 'hours');
      nowTime.add(clockDiff, 'seconds');
      strDate = nowTime.format().substring(0, 19);
      strDate = strDate.replace("T", " ");
    } catch (e) {
      nowTime = new Date();
      strDate = nowTime.toISOString().substring(0, 19);
      strDate = strDate.replace("T", " ");
    }
    return strDate;
  }

  getLeftTimeStr(seconds) {
    if (seconds < 0) seconds = 0;
    seconds = seconds / 1;
    let mins = Math.floor(seconds / 60);
    if (isNaN(mins)) mins = "-";
    let secs = Math.floor(seconds - mins * 60);
    if (isNaN(secs)) secs = "-";
    else if (secs < 10) secs = "0" + secs;
    return mins + " 분 " + secs + " 초";
  }

  getLeftTimeBarLength(seconds) {
    //return {};
    if (seconds < 0) seconds = 0;
    seconds = seconds / 1;
    return { left:  - ((120 - seconds) * 290) / 120 + "px" };
  }

  renderNotice() {
    return (
      <div className="notice-win">

      </div>
    );
  }
  
  render() {
    const { sockets } = this.props;
    let { currentResult, currentWinResult } = sockets;
    let { isBeingAnimation, menuFlag, soundOn } = this.state;
    return (
      <div className="po_wrap_new centerBox">
        <HeaderBar />
        <div className="po_box_wrap_left_new" id="po_box_wrap_left_new">
          {this.renderPlayerList()}
          {/*this.renderNotice()*/}
        </div>

        <div
          className="po_box_wrap_right_new"
          style={{ display: menuFlag === 0 ? "block" : "none" }}
        >
          {this.renderRecentResult()}
        </div>
        <div
          className="po_box_wrap_right_new"
          style={{ display: menuFlag === 1 ? "block" : "none" }}
        >
          {this.renderRecentResultNumbers()}
        </div>

        <div className="po_center_wrap_new relativeBox inBox">
          <p id="clock" className="po_center_data" style={{ color: "#ffffff" }}>
            {this.getDateTimeStr()}
          </p>

          <div class="playBox">
            <div class="relativeBox">
              <div class="pl_icon start_l"></div>
              <div class="pl_icon start_r"></div>
              <ul class="s4"></ul>
              <div class="lineBox"></div>
              <div class="pl_icon end_l"></div>
              <div class="pl_icon end_r"></div>
            </div>
          </div>
          <div class="gmImg gameResultBox"></div>
          <div class="bepickLogo">
            <div id="loader">
              <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <span class="gmImg logo"></span>
          </div>
          <div id="game_board" className="game_board"></div>
          <div
            id="waiting_board"
            className="waiting_board"
            style={{ display: isBeingAnimation ? "none" : "block" }}
          >
            <div className="tx">
              <div className="date_round">
                <span id="date_round"> {currentResult.round}</span>회차
              </div>
              <div className="countdown_clock">
                <span id="countdown_clock">
                  추첨 {this.getLeftTimeStr(currentResult.secondsLeft)}전
                </span>
              </div>
            </div>
            <div className="inner">
              <div
                id="timer_gauge"
                className="gauge"
                style={this.getLeftTimeBarLength(currentResult.secondsLeft)}
              ></div>
            </div>
          </div>
          <div id="waiting_score_board" className="waiting_score_board"></div>
          <div
            id="score_board"
            className="score_board __web-inspector-hide-shortcut__"
            style={{ display: "none" }}
          >
            <div className="curr_round">
              <span className="pick_num">
                <strong>-회차</strong>
              </span>
              <span className="pick_num2">-</span>
            </div>
            <div className="result_info">
              <div className="normalball"></div>
              <div className="powerball"></div>
            </div>
            <span className="result_ball"></span>
          </div>
          <dl
            id="result_board"
            className="result_board"
            style={{ display: "none" }}
          >
            <dt>회차</dt>
            <dd className="round"></dd>
            <dt>파워볼</dt>
            <dd className="powerball"></dd>
            <dt>숫자합</dt>
            <dd className="sum"></dd>
          </dl>
          <ReactHint
            attribute="data-custom"
            events={{ hover: true }}
            onRenderContent={this.onRenderContent}
            ref={(ref) => (this.instance = ref)}
          />
        </div>
      </div>
    );
  }

  onRenderContent = (target, content) => {
    const { catId } = target.dataset;
    let botInfo = JSON.parse(catId);
    const width = 240;
    let avatarStyle = this.getAvatarStyle(botInfo.avatar);
    avatarStyle = { ...avatarStyle, zoom: "0.6", border: "0px" };
    return (
      <div className="custom-hint__content" id="pickinfo" style={{zIndex:9999}}>
        <h2>
          <span className="nickBox">
            <div className="avatar" style={avatarStyle}></div>
            &nbsp;&nbsp;
            <span className="nick">{botInfo.nickname}</span>
          </span>
        </h2>
        <ul>
          <li>
            <span className="col">구분</span>
            <span className="etype">
              리치사다리
            </span>
          </li>
          {botInfo.recentWLcounter >= 0 && (
            <li>
              <span className="col">연승</span>
              <span className={"emainrow red"}>
                ▲ {Math.abs(botInfo.recentWLcounter)}연승
              </span>
            </li>
          )}
          {botInfo.recentWLcounter < 0 && (
            <li>
              <span className="col">연승</span>
              <span className={"emainrow blue"}>
                ▼ {Math.abs(botInfo.recentWLcounter)}연패
              </span>
            </li>
          )}
          <li>
            <span className="col">구분</span>
            <span className="ewin red">{botInfo["winCount"]}승</span>
            <span className="ewin"> / </span>
            <span className="ewin blue">{botInfo["lostCount"]}패</span>{" "}
            <span className="ewin">
              (
              {Math.floor(
                (100 * botInfo["winCount"]) /
                  (botInfo["winCount"] + botInfo["lostCount"])
              )}
              %)
            </span>
          </li>
        </ul>
      </div>
    );
  };

  getAvatarStyle(avatar) {
    let X = 0;
    let Y = 0;
    Y = parseInt(avatar / 12);
    X = avatar % 12;
    X = 0 - X * 61.5 + "px";
    Y = 0 - Y * 61.5 + "px";
    let avatarStyle = {
      backgroundPosition: X + " " + Y,
    };
    return avatarStyle;
  }

  renderPlayerList() {
    const { sockets } = this.props;
    const { isBeingAnimation } = this.state;
    let { playerList } = sockets;
    playerList.sort(function (a, b) {
      return a.recentWLcounter - b.recentWLcounter;
    });
    let rows = [];
    if (isBeingAnimation) return rows;
    for (let i = 0; i < playerList.length; i++) {
      let item = playerList[playerList.length - i - 1];
      rows.push(
        <div
          className="po_box_left_new"
          data-custom
          data-custom-at="top"
          data-cat-id={JSON.stringify(item)}
        >
          <div className={item.recentWLcounter > 0 ? "number" : "lostNumber"}>
            {Math.abs(item.recentWLcounter)}
          </div>
          <div class="avatar" style={this.getAvatarStyle(item.avatar)} />
          <a className="name" style={{ textAlign: "left" }}>
            {item.nickname}
          </a>
          <div style={{ position: "absolute", left: "168px" }}>
            <div className={"RES_" + item.result}></div>
          </div>
        </div>
      );
    }
    return rows;
  }

  renderRecentResult() {
    const { sockets } = this.props;
    let { recentResult, currentWinResult } = sockets;
    let { isBeingAnimation } = this.state;
    let rows = [];
    for (let i = 0; i < recentResult.length; i++) {
      let item = recentResult[recentResult.length - i - 1];
      if (isBeingAnimation === true && currentWinResult.round === item.round)
        continue;
      rows.push(
        <div className="po_box_right_new">
          <ul>
            <li className="ROUND">
              <span>{item["pick_num2"]}회</span>
            </li>
            <li className={"START_" + item["lr"]}></li>
            <li className={"LINE_" + item["line"]}></li>
            <li className={"OE_" + item["oe"]}></li>
          </ul>
        </div>
      );
    }
    return rows;
  }

  renderRecentResultNumbers() {
    const { sockets } = this.props;
    let { recentResult, currentWinResult } = sockets;
    let { isBeingAnimation } = this.state;
    let rows = [];
    for (let i = 0; i < recentResult.length; i++) {
      let item = recentResult[recentResult.length - i - 1];
      if (isBeingAnimation === true && currentWinResult.round === item.round)
        continue;
      rows.push(
        <div className="po_box_right_new">
          {item["pick_num2"]}({item["pick_num"]})<br />
          <ul>
            <li className="ball"></li>
            <li className="power-ball"></li>
          </ul>
        </div>
      );
    }
    return rows;
  }
}

function mapStateToProps(state) {
  const { modal, sockets, game } = state;
  return {
    modal,
    sockets,
    game,
  };
}

const connectedHomePage = connect(mapStateToProps)(GamePage);
export { connectedHomePage as GamePage };
