import {Howl} from 'howler';
var readySound = require('../_assets/sound/sadari_result.mp3');
var ballSound = require('../_assets/sound/ball.mp3');

const readySoundPlayer = new Howl({src: [readySound], volume: 0.2});
const ballSoundPlayer = new Howl({src: [ballSound], volume: 1.8});

export const soundPlayers = {
    readySoundPlayer,
    ballSoundPlayer,
};
