import { authHeader } from '../_helpers';
var apiUrl = window.backendUrl;

export const gameService = {
    fetchConfig,
};

function fetchConfig() {
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json' },
    };
    return fetch(`${apiUrl}/config/fetchsetting`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
