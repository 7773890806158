import { modalConstants } from '../_constants';
const initialState = {
    isShowManual : false,
    isShowJokbo : false,
    isShowResult: false,
};
export function modal(state = initialState, action) {
    switch (action.type) {
        case modalConstants.SHOW_MANUAL:
            state.isShowManual = action.data;
            if (action.data) {
                state.isShowResult = false;
                state.isShowJokbo = false;
            }
            return {...state };
        case modalConstants.SHOW_JOKBO:
            state.isShowJokbo = action.data;
            if (action.data) {
                state.isShowResult = false;
                state.isShowManual = false;
            }
            return {...state };
        case modalConstants.SHOW_RESULT:
            state.isShowResult = action.data;
            if (action.data) {
                state.isShowJokbo = false;
                state.isShowManual = false;
            }
            return {...state };
        default:
            return state
    }
}
