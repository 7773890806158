import { modalConstants } from '../_constants';

export const modalActions = {
    showManual,
    showJokbo,
    showResult,
};

function showManual(flag) {
    return { type: modalConstants.SHOW_MANUAL,  data:flag };
}

function showJokbo(flag) {
    return { type: modalConstants.SHOW_JOKBO,  data:flag };
}

function showResult(flag) {
    return { type: modalConstants.SHOW_RESULT,  data:flag };
}
