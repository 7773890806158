import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PieChart } from 'react-minimal-pie-chart';
import $ from "jquery";
var classNames = require('classnames');
var moment = require('moment');
const DATA_SIZE = 20;

const TYPE = {
    OE: 0,
    LR: 1,
    COUNT : 2,
    COMBO: 3
}

class HistoryComponent extends Component {

    state = {
        dataIndex : DATA_SIZE,
        type: TYPE.OE,
        firstLoaded: true,
        patterns: <div></div>
    };

    constructor(props){
        super(props);
    }
    
    componentWillReceiveProps(nextProps) {
      try{
        let historyA = this.props.sockets.recentResult;
        if(historyA.length < 2) return;
        historyA = historyA[historyA.length-1]
        let historyB = nextProps.sockets.recentResult;
        historyB = historyB[historyB.length-1]
        if (historyA['pick_num2'] != historyB['pick_num2'] || this.state.patterns === <div></div>) {
          this.setState({patterns : this.renderPattern()});
        }
      }catch(ex){
        this.setState({patterns : this.renderPattern()});
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
      try{
        if(this.state.firstLoaded !== true && prevState.type === this.state.type) return;
        if(this.props.sockets.recentResult.length <3) return;
        this.setState({patterns : this.renderPattern()});
        setTimeout(function(){
          let history_data = document.querySelector('#history_data');
          if(document.querySelector('#history_data table tbody tr').children.length === 0) return;
          history_data.scrollTo({
            top: 0,
            left: 100000,
          });
        }, 100);
        this.setState({firstLoaded: false});
      }catch(ex){
      }
    }

    getBarElement(val1, val2){
        if(val1 <  val2)
            return (<div className="gauge right" style={{"right": (0- val1) + "%"}}></div>);
        else
            return (<div className="gauge left" style={{"left": (0- val2) + "%"}}></div>);
    }

    loadMore(){
        this.setState({dataIndex: this.state.dataIndex + DATA_SIZE});
    }

    selectPattern(type){
        this.setState({type: type});
    }

    render(){
        let {recentResult, currentWinResult} = this.props.sockets;
        let historyData = JSON.parse(JSON.stringify(recentResult));
        historyData = historyData.reverse();
        let totalCount = recentResult.length
        let leftCount = recentResult.filter((obj) => obj.lr === 'LEFT').length
        let oddCount = recentResult.filter((obj) => obj.oe === 'ODD').length
        let threeCount = recentResult.filter((obj) => obj.line === '3').length
        let gubun1Count = recentResult.filter((obj) => obj.gubun === '1').length
        let gubun2Count = recentResult.filter((obj) => obj.gubun === '2').length
        let gubun3Count = recentResult.filter((obj) => obj.gubun === '3').length
        let gubun4Count = recentResult.filter((obj) => obj.gubun === '4').length
        return (
          <div id="wrapper">
            <div className="wrapper">
              <div id="pt_stats" className="pt_stats bd_all">
                <div className="sheader bd_bottom">
                  <strong>최근 <span className="red">720게임</span></strong> 기준 통계데이터
                </div>
                <table>
                  <tbody>
                      <tr>
                        <td className="stats_box">
                            <div className="stats_item">
                              <ul className="table">
                                  <li className="left"><span className={"r_icon round15 bubble_ladder3_fd1_1 " + (leftCount >= totalCount/2?"":"opt_bg_0")}></span> </li>
                                  {this.renderBarChart(leftCount, totalCount)}
                                  <li className="right"><span className={"r_icon round15 bubble_ladder3_fd1_2 " + (leftCount < totalCount/2?"":"opt_bg_0")}></span></li>
                              </ul>
                            </div>
                            <div className="stats_item">
                              <ul className="table">
                                  <li className="left"><span className={"r_icon round15 bubble_ladder3_fd2_1 " + (threeCount >= totalCount/2?"":"opt_bg_0")}></span> </li>
                                  {this.renderBarChart(threeCount, totalCount)}
                                  <li className="right"><span className={"r_icon round15 bubble_ladder3_fd2_2 "  + (threeCount < totalCount/2?"":"opt_bg_0")}></span></li>
                              </ul>
                            </div>
                            <div className="stats_item">
                              <ul className="table">
                                  <li className="left"><span className={"r_icon round15 bubble_ladder3_fd3_1 " + (oddCount >= totalCount/2?"":"opt_bg_0")}></span> </li>
                                  {this.renderBarChart(oddCount, totalCount)}
                                  <li className="right"><span className={"r_icon round15 bubble_ladder3_fd3_2 " + (oddCount < totalCount/2?"":"opt_bg_0")}></span></li>
                              </ul>
                            </div>
                        </td>
                        <td className="pie_box">
                          <PieChart
                            data={[
                              { title: 'One', value: gubun1Count, color: '#e62626' },
                              { title: 'Two', value: gubun2Count, color: '#1ba9de' },
                              { title: 'Three', value: gubun3Count, color: '#1787b0' },
                              { title: 'Three', value: gubun4Count, color: '#c01e1e' },
                            ]}
                          />
                        </td>
                        <td className="pie_data_box">
                            <ul id="dt_pie_graph_data">
                            <li className="in" style={{color: Math.max(gubun1Count, gubun2Count, gubun3Count, gubun4Count) == gubun1Count?"#e62626":"#3f3f3f"}}>
                                  <span className="eclipse round35 inline_block" style={{background:"#e62626"}}></span>
                                  <span className="text" style={{fontWeight:Math.max(gubun1Count, gubun2Count, gubun3Count, gubun4Count) == gubun1Count?"bold":"normal"}}>좌3짝</span>
                                  <span className="split">-</span>
                                  <span className="num tahoma bold">{gubun1Count}</span>
                                  <span className="num tahoma per">({this.percent(gubun1Count, totalCount)}%)</span>
                              </li>
                              <li className="in" style={{color: Math.max(gubun1Count, gubun2Count, gubun3Count, gubun4Count) == gubun2Count?"#1ba9de":"#3f3f3f"}}>
                                  <span className="eclipse round35 inline_block" style={{background: "#1ba9de"}}></span>
                                  <span className="text" style={{fontWeight:Math.max(gubun1Count, gubun2Count, gubun3Count, gubun4Count) == gubun2Count?"bold":"normal"}}>좌4홀</span>
                                  <span className="split">-</span>
                                  <span className="num tahoma bold">{gubun2Count}</span>
                                  <span className="num tahoma per">({this.percent(gubun2Count, totalCount)}%)</span>
                              </li>
                              <li className="in" style={{color: Math.max(gubun1Count, gubun2Count, gubun3Count, gubun4Count) == gubun3Count?"#1787b0":"#3f3f3f"}}>
                                  <span className="eclipse round35 inline_block" style={{background:"#1787b0"}}></span>
                                  <span className="text" style={{fontWeight:Math.max(gubun1Count, gubun2Count, gubun3Count, gubun4Count) == gubun3Count?"bold":"normal"}}>우3홀</span>
                                  <span className="split">-</span>
                                  <span className="num tahoma bold">{gubun3Count}</span>
                                  <span className="num tahoma per">({this.percent(gubun3Count, totalCount)}%)</span>
                              </li>
                              <li className="in" style={{color: Math.max(gubun1Count, gubun2Count, gubun3Count, gubun4Count) == gubun4Count?"#c01e1e":"#3f3f3f"}}>
                                  <span className="eclipse round35 inline_block" style={{background:"#c01e1e"}}></span>
                                  <span className="text" style={{fontWeight:Math.max(gubun1Count, gubun2Count, gubun3Count, gubun4Count) == gubun4Count?"bold":"normal"}}>우4짝</span>
                                  <span className="split">-</span>
                                  <span className="num tahoma bold">{gubun4Count}</span>
                                  <span className="num tahoma per">({this.percent(gubun4Count, totalCount)}%)</span>
                              </li>
                            </ul>
                        </td>
                      </tr>
                  </tbody>
                </table>
            </div>
            <div className="pt_category bd_all">
                <ul className="table" id="pt_btngrp">
                  <li>
                    <a className={"block " + (this.state.type === TYPE.OE ? "selected": "")} onClick={this.selectPattern.bind(this, TYPE.OE)}>홀짝패턴
                        <em className="hbox hide"></em>
                    </a>
                  </li>
                  <li>
                    <a className={"block " + (this.state.type === TYPE.LR ? "selected": "")}  onClick={this.selectPattern.bind(this, TYPE.LR)}>좌우패턴
                        <em className="hbox hide"></em>
                    </a>
                  </li>
                  <li>
                    <a className={"block " + (this.state.type === TYPE.COUNT ? "selected": "")} onClick={this.selectPattern.bind(this, TYPE.COUNT)}>사다리수패턴
                        <em className="hbox hide"></em>
                    </a>
                  </li>
                  <li>
                    <a className={"block " + (this.state.type === TYPE.COMBO ? "selected": "")} onClick={this.selectPattern.bind(this, TYPE.COMBO)}>조합패턴
                        <em className="hbox hide"></em>
                    </a>
                  </li>
                  <li>
                      <div className="block null">-</div>
                  </li>
                  <li>
                      <div className="block null">-</div>
                  </li>
                  <li>
                      <div className="block null">-</div>
                  </li>
                  {
                    /*
                    <li>
                      <span className="block">
                        <select id="pt_option" className="round">
                            <option value="20" selected="">+ 기본패턴</option>
                            <option value="31" selected="">+ 중국점1군패턴</option>
                            <option value="32" selected="">+ 중국점2군패턴</option>
                            <option value="33" selected="">+ 중국점3군패턴</option>
                            <option value="1">+ 1매패턴</option>
                            <option value="2">+ 2매패턴</option>
                            <option value="3">+ 3매패턴</option>
                            <option value="4">+ 4매패턴</option>
                            <option value="5">+ 5매패턴</option>
                            <option value="6">+ 6매패턴</option>
                            <option value="7">+ 7매패턴</option>
                            <option value="8">+ 8매패턴</option>
                            <option value="9">+ 9매패턴</option>
                            <option value="10">+ 10매패턴</option>
                        </select>
                      </span>
                  </li>
                     */
                  }
                </ul>
            </div>
            <div className="pattern padding-none" id="pt_content">
                {this.state.patterns}
            </div>
            <div className="dt_list_box bd_all">
                <table id="dt_list" className="dt_list">
                  <colgroup>
                      <col width="180"/>
                      <col width="120"/>
                      <col width="150"/>
                      <col width="150"/>
                      <col width="150"/>
                  </colgroup>
                  <thead>
                      <tr>
                        <th>날짜/회차</th>
                        <th>시간</th>
                        <th>시작</th>
                        <th>줄수</th>
                        <th>결과</th>
                      </tr>
                  </thead>
                  <tbody>
                    {
                        historyData.map((item, index) => {
                            if(index >= this.state.dataIndex) return ;
                            return <tr className="tlist" key={index}>
                                <td>
                                    <div className="date">
                                    <span className="time">{moment(item['pick_date']).format('yyyy-MM-DD')}</span><span className="st">-</span><strong>{item['pick_num2']}</strong>
                                    <em className="hbox hide"></em>
                                    </div>
                                </td>
                                <td>
                                    <div className="date"><span className="time">{moment(item['pick_date']).format('HH-mm')}</span></div>
                                </td>
                                <td>
                                    <span className={'START_'+item["lr"]}></span>
                                </td>
                                <td>
                                    <span className={'LINE_' + item["line"]}></span>
                                </td>
                                <td>
                                    <span className={'OE_' + item["oe"]}></span>
                                </td>
                            </tr>
                        })
                    }
                  </tbody>
                </table>
            </div>
            <div id="dt_more" className="dt_more pointer bd_all" onClick={this.loadMore.bind(this)}>데이터더보기<em className="hbox hide"></em></div>
        </div>
    </div>
        );
    }

    renderBarChart(valueA, totalCount){
      let percent = this.percent(valueA, totalCount);
      let percent2 = this.percent(totalCount-valueA, totalCount);
      return <li className="center middle">
                <div className="tgraph round15 opt_bd_0">
                    <div className={"bar round15 opt_bg_" + (percent >= percent2 ?"1":"2")} style={{width:percent + '%'}}></div>
                    <span className="block text left">
                      <div className={"number color_" + (percent >= percent2 ?"w":"g")}><strong>{valueA}</strong> <span>({percent}%)</span></div>
                    </span>
                    <span className="block text right">
                      <div className={"number color_" + (percent < percent2 ?"w":"g")}><strong>{totalCount-valueA}</strong> <span>({percent2}%)</span></div>
                    </span>
                </div>
              </li>
    }

    renderPattern(){
        let rows = [];
        let subrows = [];
        let trRows = [], typeRows = [];
        const {type} = this.state;
        let {recentResult} = this.props.sockets;
        let maxLength = 0;
        let pongDang = 0, yonSok = 0;
        let strArray = '';
        let pongDangRow = 0, yonSokRow = 0;
        let oddCount =0 , evenCount = 0;
        let oddYonSokCount =0 , evenYonSokCount = 0;
        let firstItem = {};
        let gubunMaxCount = {'1':0, '2':0, '3':0, '4':0};
        if(recentResult.length > 1) firstItem = recentResult[recentResult.length-1]['oe'];
        
        for(let i= 0; i < recentResult.length; i++){
            let item = recentResult[i];
            if(type === TYPE.OE){
              if(item['oe'] === 'ODD')  oddCount++;
              if(item['oe'] === 'EVEN') evenCount++;
              if(subrows.length > 0 && i > 0 && recentResult[i-1]['oe'] != null && recentResult[i-1]['oe'] != item['oe']){
                  trRows.push(subrows);
                  typeRows.push(item['oe'] === 'ODD'? 2:1)
                  if(item['oe'] === 'ODD'){
                    if(evenYonSokCount < subrows.length) evenYonSokCount= subrows.length;
                  }else{
                    if(oddYonSokCount < subrows.length) oddYonSokCount= subrows.length;
                  }
                  if(maxLength < subrows.length) maxLength = subrows.length;
                  subrows = [];
              }
              subrows.push(
                <tr>
                    <td className="chn">
                        <div><span className={"r_icon r_icon_round round15 " + (item['oe']!=='ODD'?'bubble_ladder3_fd1_round_2': 'bubble_ladder3_fd1_round_1')}>{item['pick_num2']}</span></div>
                    </td>
                </tr>
              );
            } else if(type === TYPE.LR){
              if(item['lr'] === 'LEFT')  oddCount++;
              if(item['lr'] === 'RIGHT') evenCount++;
              if(subrows.length > 0 && i > 0 && recentResult[i-1]['lr'] != null && recentResult[i-1]['lr'] != item['lr']){
                  trRows.push(subrows);
                  typeRows.push(item['lr'] === 'LEFT'? 2:1)
                  if(item['lr'] === 'LEFT'){
                    if(evenYonSokCount < subrows.length) evenYonSokCount= subrows.length;
                  }else{
                    if(oddYonSokCount < subrows.length) oddYonSokCount= subrows.length;
                  }
                  if(maxLength < subrows.length) maxLength = subrows.length;
                  subrows = [];
              }
              subrows.push(
                <tr>
                    <td className="chn">
                        <div><span className={"r_icon r_icon_round round15 " + (item['lr']!=='LEFT'?'bubble_ladder3_fd1_round_2': 'bubble_ladder3_fd1_round_1')}>{item['pick_num2']}</span></div>
                    </td>
                </tr>
              );
            } else if(type === TYPE.COUNT){
              if(item['line'] === '3')  oddCount++;
              if(item['line'] === '4') evenCount++;
              if(subrows.length > 0 && i > 0 && recentResult[i-1]['line'] != null && recentResult[i-1]['line'] != item['line']){
                  trRows.push(subrows);
                  typeRows.push(item['line'] === '3'? 2:1)
                  if(item['line'] === '3'){
                    if(evenYonSokCount < subrows.length) evenYonSokCount= subrows.length;
                  }else{
                    if(oddYonSokCount < subrows.length) oddYonSokCount= subrows.length;
                  }
                  if(maxLength < subrows.length) maxLength = subrows.length;
                  subrows = [];
              }
              subrows.push(
                <tr>
                    <td className="chn">
                        <div><span className={"r_icon r_icon_round round15 " + (item['line']!=='3'?'bubble_ladder3_fd1_round_2': 'bubble_ladder3_fd1_round_1')}>{item['pick_num2']}</span></div>
                    </td>
                </tr>
              );
            } else if(type === TYPE.COMBO){
              if(subrows.length > 0 && i > 0 && recentResult[i-1]['gubun'] != null && recentResult[i-1]['gubun'] != item['gubun']){
                  trRows.push(subrows);
                  typeRows.push(recentResult[i-1]['gubun'])
                  if(maxLength < subrows.length) maxLength = subrows.length;
                  if(gubunMaxCount[recentResult[i-1]['gubun']] < subrows.length) gubunMaxCount[recentResult[i-1]['gubun']] = subrows.length;
                  subrows = [];
              }
              subrows.push(
                <tr>
                    <td className="chn">
                        <div><span className={"r_icon r_icon_round round15 bubble_ladder3_fd4_round_" + item['gubun']}>{item['pick_num2']}</span></div>
                    </td>
                </tr>
              );
            }
        }
        for(let i=0; i < trRows.length; i++){
          pongDang = pongDang + (trRows[i].length === 1?1:0);
          yonSok = yonSok + (trRows[i].length > 1?1:0); 
          strArray += (trRows[i].length === 1?'1':'0');
          let blankRows = [];
          for(let j=trRows[i].length; j < maxLength; j ++ ){
              blankRows.push(
                  <tr>
                      <td className="bottom chn">
                      </td>
                    </tr>
              )
          }
          rows.push(<td className="box_td bubble_ladder3_opt_2">
              <table className="list">
                <tbody>
                    <tr>
                      {
                        type === TYPE.OE && 
                        <td className="name chn">
                          <div className={"font12 bubble_ladder3_fd3_text_" + typeRows[i]}></div>
                        </td>
                      }
                      {
                        type === TYPE.LR && 
                        <td className="name chn">
                          <div className={"font12 bubble_ladder3_fd1_text_" + typeRows[i]}></div>
                        </td>
                      }
                      {
                        type === TYPE.COUNT && 
                        <td className="name chn">
                          <div className={"font12 bubble_ladder3_fd2_text_" + typeRows[i]}></div>
                        </td>
                      }
                      {
                        type === TYPE.COMBO && 
                        <td className="name chn">
                          <div className={"font12 bubble_ladder3_fd4_text_" + typeRows[i]}></div>
                        </td>
                      }
                      
                    </tr>
                    {trRows[i]}
                    {blankRows}
                    <tr>
                      <td className="bottom chn">
                          <div><span className={"bold font10 " + (trRows[i].length >= 5? "red":"gray")}>{trRows[i].length}</span></div>
                      </td>
                    </tr>
                    <tr>
                      <td className="bottom bd_bottom">
                          <div><span className="font10 gray">{i+1}</span></div>
                      </td>
                    </tr>
                </tbody>
              </table>
              <em className="hbox hide"></em>
          </td>)
        }
        pongDangRow = strArray.split('0').sort(
          function (a, b) {
              return b.length - a.length;
          }
        )[0].length;

        yonSokRow = strArray.split('1').sort(
          function (a, b) {
              return b.length - a.length;
          }
        )[0].length;

        const gubun1Count = recentResult.filter((obj) => obj.gubun === '1').length;
        const gubun2Count = recentResult.filter((obj) => obj.gubun === '2').length;
        const gubun3Count = recentResult.filter((obj) => obj.gubun === '3').length;
        const gubun4Count = recentResult.filter((obj) => obj.gubun === '4').length;
        

        return <div>
                {
                 type !== TYPE.COMBO && 
                 <div className="pt_subtab bd_all">
                      {
                        type ===  TYPE.OE &&  <span className="r_icon round35 bubble_ladder3_fd3_1"></span>
                      }
                      {
                        type ===  TYPE.LR &&  <span className="r_icon round35 bubble_ladder3_fd1_1"></span>
                      }
                      {
                        type ===  TYPE.COUNT &&  <span className="r_icon round35 bubble_ladder3_fd2_1"></span>
                      }
                      <span className="text">{oddCount}번</span>
                      <span className="per">({this.percent(oddCount, recentResult.length)}%)</span>
                      <a><strong>{oddYonSokCount}연속</strong></a>
                      <span className="split">|</span>
                      {
                        type ===  TYPE.OE &&  <span className="r_icon round35 bubble_ladder3_fd3_2"></span>
                      }
                      {
                        type ===  TYPE.LR &&  <span className="r_icon round35 bubble_ladder3_fd1_2"></span>
                      }
                      {
                        type ===  TYPE.COUNT &&  <span className="r_icon round35 bubble_ladder3_fd2_2"></span>
                      }
                      <span className="text">{evenCount}번</span>
                      <span className="per">({this.percent(evenCount, recentResult.length)}%)</span>
                      <a><strong>{evenYonSokCount}연속</strong></a>
                      <div className="add_data">
                        <span className="r_icon round35 pt_turn">꺾임</span>
                        <span className="text">{trRows.length}번</span>
                        <span className="split">|</span>
                        <span className="r_icon round35 pt_pj_1">퐁당</span>
                        <span className="text">{pongDang}번</span>
                        <span className="per">({this.percent(pongDang, trRows.length)}%)</span>
                        <a><strong>{pongDangRow}연속</strong></a>
                        <span className="split">|</span>
                        <span className="r_icon round35 pt_pj_2">연속</span>
                        <span className="text">{yonSok}번</span>
                        <span className="per">({this.percent(yonSok, trRows.length)}%)</span>
                        <a><strong>{yonSokRow}연속</strong></a>
                      </div>
                  </div>
                }

                {
                 type === TYPE.COMBO && 
                <div className="pt_subtab bd_all">
                  <span className="r_icon round35 bubble_ladder3_fd4_1"></span>
                  <span className="text">{gubun1Count}번</span>
                  <a><strong>{gubunMaxCount['1']}연속</strong></a>
                  <span className="split">|</span>
                  <span className="r_icon round35 bubble_ladder3_fd4_2"></span>
                  <span className="text">{gubun2Count}번</span>
                  <a><strong>{gubunMaxCount['2']}연속</strong></a>
                  <span className="split">|</span>
                  <span className="r_icon round35 bubble_ladder3_fd4_3"></span>
                  <span className="text">{gubun3Count}번</span>
                  <a><strong>{gubunMaxCount['3']}연속</strong></a>
                  <span className="split">|</span>
                  <span className="r_icon round35 bubble_ladder3_fd4_4"></span>
                  <span className="text">{gubun4Count}번</span>
                  <a><strong>{gubunMaxCount['4']}연속</strong></a>
                  <div className="add_data">
                    <span className="r_icon round35 pt_turn">꺾임</span>
                    <span className="text">{typeRows.length}번</span>
                  </div>
                </div>
                }
                  
                  <div id="history_data" className="pt_box bd_all">
                      <table>
                        <tbody>
                            <tr>
                              {rows}
                            </tr>
                        </tbody>
                      </table>
                  </div>
                </div>;
    }

    percent(count, total) {
      return Math.floor(10000 * count / total) / 100;
    }
}


function mapStateToProps(state) {
    const {  sockets } = state;
    return {
        sockets
    };
}


const connectedComponent = connect(mapStateToProps)(HistoryComponent);
export {connectedComponent as HistoryComponent};
