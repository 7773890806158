import io from 'socket.io-client';
import { userService } from '../_services';
import {messageTypes, gameName} from '../_constants/socket.constants.js';
var socketUrl = window.socketUrl;
const socket = io( socketUrl );
var storeObject = undefined;
export const socketActions = {
    init,
    emit,
    emitWithAck,
};
socket.on('connect', function(){
  emit("gameinit", gameName)
})

socket.on('disconnect', function(data) {
    console.log('Client disconnected |', data, '|', typeof data);
});

socket.on('connect_error', function(err){
  console.log(err);
});

function init( store ){
  storeObject = store;
  Object.keys( messageTypes )
  .forEach( type => socket.on( type, function(data) {
      store.dispatch({ type, data });
   }));
}

function emit( type, payload ){
  socket.emit( type, payload );
}

function emitWithAck(type, payload, ack){
  socket.emit(type, payload, ack);
}
