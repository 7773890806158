export const gameName = "ntry"

export const socketConstants = {
    GAME_START: gameName + "/game_start",
    GAME_END:   gameName + "/game_end",
    RECENT_RESULT:   gameName + "/recent_result",
    AI_BET:   gameName + "/ai_bet",
    BOT_LIST:   gameName + "/bot_list",
    GAME_GRADE: gameName +  "/grade",
    TODAY_DATA: gameName +  "/today_data",
    STATISTICS: gameName +  "/statistics",
};

// makes an object of the form {userJoined: 'userJoined'}
export const messageTypes = [
    gameName + '/game_start',
    gameName + '/game_end',
    gameName + '/grade',
    gameName + '/ai_bet',
    gameName + '/bot_list',
    gameName + '/recent_result',
    gameName + '/today_data',
    gameName + '/statistics'
].reduce((accum, msg) => {
  accum[ msg ] = msg
  return accum;
}, {})
