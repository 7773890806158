import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactHowler from 'react-howler';
import {Howl, Howler} from 'howler';
import { modalActions, userActions, soundActions } from '../_actions';
import { soundPlayers } from '../_helpers';
var classNames = require('classnames');

class Loading extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){

  }

  render()
  {
      const { sockets} = this.props;
      return (
          <div className="wrapTop" id="wrapTop">
            <section style={{display:sockets.isLoaded?"none":"block"}}>
                  <div className="fp-waiting" style={{'marginTop': "180px"}}>
                    <svg className="fp-loading-sharp-fill" width="212px" height="212px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                      <rect x="0" y="0" width="176" height="176" fill="rgba(128,128,128,0)" className="bk"></rect>
                        <rect x="-20" y="-20" width="30" height="30" transform="translate(20 20) scale(1 1)" fill="rgba(128,128,128,.8)" className="sq">
                          <animate attributeName="fill" from="rgba(128,128,128,0)" to="rgba(128,128,128,.8)" repeatCount="indefinite" dur="1.6s" begin="0.0s" values="rgba(128,128,128,.8);rgba(128,128,128,0);rgba(128,128,128,0);rgba(128,128,128,.8);rgba(128,128,128,.8)" keyTimes="0;0.1;0.2;0.4;1"></animate>
                          <animateTransform attributeName="transform" type="scale" additive="sum" from="0.8" to="1" repeatCount="indefinite" begin="0.0s" dur="1.6s" values="1;0.8;0.8;1;1" keyTimes="0;0.1;0.2;0.4;1"></animateTransform>
                        </rect>
                        <rect x="-20" y="-20" width="30" height="30" transform="translate(55 20) scale(1 1)" fill="rgba(128,128,128,.8)" className="sq">
                          <animate attributeName="fill" from="rgba(128,128,128,0)" to="rgba(128,128,128,.8)" repeatCount="indefinite" dur="1.6s" begin="0.4s" values="rgba(128,128,128,.8);rgba(128,128,128,0);rgba(128,128,128,0);rgba(128,128,128,.8);rgba(128,128,128,.8)" keyTimes="0;0.1;0.2;0.4;1"></animate>
                          <animateTransform attributeName="transform" type="scale" additive="sum" from="0.8" to="1" repeatCount="indefinite" begin="0.4s" dur="1.6s" values="1;0.8;0.8;1;1" keyTimes="0;0.1;0.2;0.4;1"></animateTransform>
                        </rect>
                        <rect x="-20" y="-20" width="30" height="30" transform="translate(55 55) scale(1 1)" fill="rgba(128,128,128,.8)" className="sq">
                          <animate attributeName="fill" from="rgba(128,128,128,0)" to="rgba(128,128,128,.8)" repeatCount="indefinite" dur="1.6s" begin="0.4s" values="rgba(128,128,128,.8);rgba(128,128,128,0);rgba(128,128,128,0);rgba(128,128,128,.8);rgba(128,128,128,.8)" keyTimes="0;0.1;0.2;0.4;1"></animate>
                          <animateTransform attributeName="transform" type="scale" additive="sum" from="0.8" to="1" repeatCount="indefinite" begin="0.4s" dur="1.6s" values="1;0.8;0.8;1;1" keyTimes="0;0.1;0.2;0.4;1"></animateTransform>
                        </rect>
                        <rect x="-20" y="-20" width="30" height="30" transform="translate(20 55) scale(1 1)" fill="rgba(128,128,128,.8)" className="sq">
                          <animate attributeName="fill" from="rgba(128,128,128,0)" to="rgba(128,128,128,.8)" repeatCount="indefinite" dur="1.6s" begin="0.0s" values="rgba(128,128,128,.8);rgba(128,128,128,0);rgba(128,128,128,0);rgba(128,128,128,.8);rgba(128,128,128,.8)" keyTimes="0;0.1;0.2;0.4;1"></animate>
                          <animateTransform attributeName="transform" type="scale" additive="sum" from="0.8" to="1" repeatCount="indefinite" begin="0.0s" dur="1.6s" values="1;0.8;0.8;1;1" keyTimes="0;0.1;0.2;0.4;1"></animateTransform>
                        </rect>
                    </svg>
                  </div>
             </section>
          </div>
        );
  }
}

function mapStateToProps(state) {
    const {  sockets } = state;
    return {
        sockets
    };
}


const connectedLoading = connect(mapStateToProps)(Loading);
export {connectedLoading as Loading};
