export const M_SETTING = {
    R3 : [
        {
            "pos": {
                "top": '18%',
                "right": '4%'
            },
            "size": {
                "height": "17%"
            },
            "spd": 192
        },
        {
            "pos": {
                "top": '29%',
                "right": '4%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '29%',
                "left": '7%'
            },
            "size": {
                "height": "19%"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": '47.5%',
                "left": '7%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '47.5%',
                "right": '4%'
            },
            "size": {
                "height": "19%"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": '65.5%',
                "right": '4%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '65.5%',
                "left": '7%'
            },
            "size": {
                "height": "17%"
            },
            "spd": 105
        }
    ],
    R4: [
        {
            "pos": {
                "top": '19%',
                "right": '4%'
            },
            "size": {
                "height": "15%"
            },
            "spd": 192
        },
        {
            "pos": {
                "top": '28%',
                "right": '4%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '28%',
                "left": '7%'
            },
            "size": {
                "height": "15%"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": '40.5%',
                "left": '7%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '40.5%',
                "right": '4%'
            },
            "size": {
                "height": "15%"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": '54%',
                "right": '4%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '54%',
                "left": '7%'
            },
            "size": {
                "height": "15%"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": '67%',
                "left": '7%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '67%',
                "right": '4%'
            },
            "size": {
                "height": "15%"
            },
            "spd": 192
        }
    ],
    L3: [
        {
            "pos": {
                "top": '18%',
                "left": '7%'
            },
            "size": {
                "height": "17%"
            },
            "spd": 192
        },
        {
            "pos": {
                "top": '29%',
                "left": '7%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '29%',
                "right": '4%'
            },
            "size": {
                "height": "19%"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": '47.5%',
                "right": '4%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '47.5%',
                "left": '7%'
            },
            "size": {
                "height": "19%"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": '66%',
                "left": '7%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '66%',
                "right": '4%'
            },
            "size": {
                "height": "17%"
            },
            "spd": 105
        },
    ],
    L4: [
        {
            "pos": {
                "top": '19%',
                "left": '7%'
            },
            "size": {
                "height": "15%"
            },
            "spd": 192
        },
        {
            "pos": {
                "top": '28%',
                "left": '7%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '28%',
                "right": '4%'
            },
            "size": {
                "height": "15%"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": '40.5%',
                "right": '4%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '40.5%',
                "left": '7%'
            },
            "size": {
                "height": "15%"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": '54%',
                "left": '7%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '54%',
                "right": '4%'
            },
            "size": {
                "height": "15%"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": '67%',
                "right": '4%'
            },
            "size": {
                "width": "87%"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": '67%',
                "left": '7%'
            },
            "size": {
                "height": "15%"
            },
            "spd": 192
        }
    ]
};


export const SETTING = {
    R3 : [
        {
            "pos": {
                "top": 54,
                "right": 18
            },
            "size": {
                "height": "36px"
            },
            "spd": 192
        },
        {
            "pos": {
                "top": 75,
                "right": 18
            },
            "size": {
                "width": "226px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 88,
                "left": 16
            },
            "size": {
                "height": "48px"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": 121,
                "left": 16
            },
            "size": {
                "width": "226px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 136,
                "right": 18
            },
            "size": {
                "height": "48px"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": 169,
                "right": 18
            },
            "size": {
                "width": "226px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 169,
                "left": 16
            },
            "size": {
                "height": "36px"
            },
            "spd": 105
        }
    ],
    R4: [
        {
            "pos": {
                "top": 53,
                "right": 18
            },
            "size": {
                "height": "24px"
            },
            "spd": 192
        },
        {
            "pos": {
                "top": 65,
                "right": 18
            },
            "size": {
                "width": "220px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 65,
                "left": 16
            },
            "size": {
                "height": "42px"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": 103,
                "left": 16
            },
            "size": {
                "width": "224px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 103,
                "right": 18
            },
            "size": {
                "height": "42px"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": 144,
                "right": 18
            },
            "size": {
                "width": "225px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 150,
                "left": 17
            },
            "size": {
                "height": "42px"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": 183,
                "left": 17
            },
            "size": {
                "width": "220px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 183,
                "right": 18
            },
            "size": {
                "height": "24px"
            },
            "spd": 192
        }
    ],
    L3: [
        {
            "pos": {
                "top": 54,
                "left": 16
            },
            "size": {
                "height": "36px"
            },
            "spd": 192
        },
        {
            "pos": {
                "top": 75,
                "left": 16
            },
            "size": {
                "width": "226px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 88,
                "right": 18
            },
            "size": {
                "height": "48px"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": 121,
                "right": 18
            },
            "size": {
                "width": "226px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 136,
                "left": 16
            },
            "size": {
                "height": "48px"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": 169,
                "left": 16
            },
            "size": {
                "width": "226px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 169,
                "right": 18
            },
            "size": {
                "height": "36px"
            },
            "spd": 105
        },
    ],
    L4: [
        {
            "pos": {
                "top": 53,
                "left": 16
            },
            "size": {
                "height": "24px"
            },
            "spd": 192
        },
        {
            "pos": {
                "top": 65,
                "left": 16
            },
            "size": {
                "width": "220px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 65,
                "right": 18
            },
            "size": {
                "height": "42px"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": 103,
                "right": 18
            },
            "size": {
                "width": "224px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 103,
                "left": 16
            },
            "size": {
                "height": "42px"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": 144,
                "left": 16
            },
            "size": {
                "width": "225px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 144,
                "right": 18
            },
            "size": {
                "height": "42px"
            },
            "spd": 105
        },
        {
            "pos": {
                "top": 183,
                "right": 18
            },
            "size": {
                "width": "220px"
            },
            "spd": 516
        },
        {
            "pos": {
                "top": 183,
                "left": 16
            },
            "size": {
                "height": "24px"
            },
            "spd": 192
        }
    ]
};
