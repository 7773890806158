import React from "react";
import Drawer from "react-modern-drawer";
import { connect } from "react-redux";
import { gameActions, modalActions } from "../_actions";
import { soundPlayers } from "../_helpers";
import "react-hint/css/index.css";
import "react-modern-drawer/dist/index.css";
import $ from "jquery";
import { M_SETTING as SETTING } from "../_constants";
require("../_assets/css/score/ui_game/ladder_m.css");
var moment = require('moment');

class GamePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBeingAnimation: false,
      isPlayingSound: false,
      menuFlag: 0,
      lastPlayedRound : 0,
      bShowResult: false,
      screenWidth: 0,
      screenHeight: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.startClickTimer();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sockets.isEnded && !this.state.isBeingAnimation) {
      this.startResultAnimation();
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    let soundOn = JSON.parse(localStorage.getItem("soundMOn"));
    if(soundOn === undefined) soundOn = true;
    this.setState({soundOn: soundOn});
    if(soundOn === false){
      soundPlayers.readySoundPlayer.mute(true);
      soundPlayers.ballSoundPlayer.mute(true);
    }else{
      soundPlayers.readySoundPlayer.mute(false);
      soundPlayers.ballSoundPlayer.mute(false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      ...this.state,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    });
    console.log("state = ", this.state);
  }

  startResultAnimation() {
    let self = this;
    const { sockets } = this.props;
    let { currentResult, currentWinResult, isEnded } = sockets;
    let { round, result } = currentWinResult;
    if(this.state.lastPlayedRound === currentWinResult['pick_num2']) return;
    this.setState({ isBeingAnimation: true });
    this.setState({lastPlayedRound: currentWinResult['pick_num2']});
    console.log("currentWinResult", currentWinResult);
    var content = $(".gmContent");
    var pickinfo = $("#pickinfo");
    var playResult = content.find(".playResult");
    var lineBox = content.find(".lineBox");
    lineBox.html("");
    var ladderLine = '<em class="line"></em>';
    var ladderBoneLine = '<li class="bone"></li>';
    var lis = "<li></li><li></li><li></li><li></li>";
    var ulClass = "s4";
    var startIcon = content.find(".start_l");
    var endIcon = content.find(".end_l");
    if (currentWinResult.gubun == 1 || currentWinResult.gubun == 3) {
       lis = "<li></li><li></li><li></li>";
       ulClass = "s3";
    }
    lis = "";
    if (currentWinResult.lr === "RIGHT") {
      startIcon = content.find(".start_r");
    }
    if (currentWinResult.oe === "EVEN") {
      endIcon = content.find(".end_r");
    }
    pickinfo.hide();
    content.find(".pPick").find("ul").html("");
    //content.find(".playBox ul").removeClass().addClass(ulClass).html(lis);
    content.find(".playBox ul").removeClass().addClass(ulClass);
    content.find(".bepickLogo").hide();
    content.find(".playBox ul").hide();
    var sType = SETTING[currentWinResult["lr"].substring(0, 1) + currentWinResult["line"]];
    lineBox.append(ladderBoneLine).find("li:eq(0)").css(sType[1].pos).animate(sType[1].size, 0);
    lineBox.append(ladderBoneLine).find("li:eq(1)").css(sType[3].pos).animate(sType[3].size, 0);
    lineBox.append(ladderBoneLine).find("li:eq(2)").css(sType[5].pos).animate(sType[5].size, 0);
    if (currentWinResult.gubun == 2 || currentWinResult.gubun == 4) {
      lineBox.append(ladderBoneLine).find("li:eq(3)").css(sType[7].pos).animate(sType[7].size, 0);
      lineBox.append(ladderBoneLine).find("li:eq(4)").css({"top": '19%',"left": '7%'}).animate({height:'65%'}, 0);
      lineBox.append(ladderBoneLine).find("li:eq(5)").css({"top": '19%',"right": '4%'}).animate({height:'65%'}, 0);
    }else{
      lineBox.append(ladderBoneLine).find("li:eq(3)").css({"top": '19%',"left": '7%'}).animate({height:'65%'}, 0);
      lineBox.append(ladderBoneLine).find("li:eq(4)").css({"top": '19%',"right": '4%'}).animate({height:'65%'}, 0);
    }
    
    new Promise(function (resolve, reject) {
      startIcon.addClass("on");
      setTimeout(function () {
        resolve();
      }, 500);
    }).then(function () {
      lineBox
        .append(ladderLine).find("em:eq(0)").css(sType[0].pos).animate(sType[0].size, sType[0].spd).promise()
        .then(function () {
          return lineBox.append(ladderLine).find("em:eq(1)")
            .css(sType[1].pos).animate(sType[1].size, sType[1].spd).promise();
        })
        .then(function () {
          return lineBox.append(ladderLine).find("em:eq(2)").css(sType[2].pos).animate(sType[2].size, sType[2].spd).promise();
        })
        .then(function () {
          return lineBox.append(ladderLine).find("em:eq(3)").css(sType[3].pos).animate(sType[3].size, sType[3].spd).promise();
        })
        .then(function () {
          return lineBox.append(ladderLine).find("em:eq(4)").css(sType[4].pos).animate(sType[4].size, sType[4].spd).promise();
        })
        .then(function () {
          return lineBox.append(ladderLine).find("em:eq(5)").css(sType[5].pos).animate(sType[5].size, sType[5].spd).promise();
        })
        .then(function () {
          return lineBox.append(ladderLine).find("em:eq(6)").css(sType[6].pos).animate(sType[6].size, sType[6].spd).promise();
        })
        .then(function () {
          if (typeof sType[7] == "object") {
            return lineBox.append(ladderLine).find("em:eq(7)").css(sType[7].pos).animate(sType[7].size, sType[7].spd).promise();
          } else {
            return console.log("null");
          }
        })
        .then(function () {
          if (typeof sType[8] == "object") {
            return lineBox.append(ladderLine).find("em:eq(8)").css(sType[8].pos).animate(sType[8].size, sType[8].spd).promise();
          } else {
            return console.log("null");
          }
        })
        .then(function () {
          endIcon.addClass("on");
          setTimeout(function () {
             content.find(".progressBar, .timeBox").hide();
             self.resultBox(currentWinResult);
          }, 1000);
        });
    });
  }

  resultBox(response) {
    let self = this;
    self.setState({ isPlayingSound: false });
    var content = $(".gmContent");
    var HTML = "<h2>" + response.pick_num2 + "회차 게임결과</h2>";
    HTML += "<p>";
    HTML += '<span class="ricon r1_' + response.lr + '"></span>' + "\n";
    HTML += '<span class="ricon r2_' + response.line + '"></span>' + "\n";
    HTML += '<span class="ricon r3_' + response.oe + '"></span>' + "\n";
    HTML += "</p>" + "\n";
    content.find(".gameResultBox").html(HTML).show();
    setTimeout(function () {
      $("#waiting_board").show();
      content.find(".lineBox, .playBox ul").html("");
      content.find(".playBox ul").show();
      content.find(".bepickLogo, .progressBar, .timeBox").show();
      content.find(".gameResultBox, .playResult").hide().html("");
      content.find(".pl_icon").removeClass("on");
      self.updateGame(response);
      self.setState({ isBeingAnimation: false });
    }, 5000);
  }

  updateGame(response) {
    var content = $(".gmContent");
    var tResult = content.find(".tResult");
    var tmp_t = tResult.find("li:eq(0)").clone();
    var sdate =
      response.pick_date.substr(0, 4) +
      "." +
      response.pick_date.substr(4, 2) +
      "." +
      response.pick_date.substr(6, 2);
    tmp_t.find("h3").text(sdate + " - " + response.pick_num2);
    tmp_t.find(".ricon").removeClass().addClass("ricon");
    tmp_t
      .find(".resultBox")
      .find("span:eq(0)")
      .addClass("r1_" + response.lr);
    tmp_t
      .find(".resultBox")
      .find("span:eq(1)")
      .addClass("r2_" + response.line);
    tmp_t
      .find(".resultBox")
      .find("span:eq(2)")
      .addClass("r3_" + response.oe);
    tResult.find("ul").prepend(tmp_t);
  }

  startClickTimer() {
    var self = this;
    let timerId = setInterval(function () {
      const { dispatch, sockets } = self.props;
      if (sockets.currentResult.secondsLeft === undefined) return;
      try {
        dispatch(gameActions.downLeftTime());
        if (sockets.currentResult.secondsLeft <= 4 &&!self.state.isPlayingSound) {
          soundPlayers.readySoundPlayer.play();
          self.setState({ isPlayingSound: true });
        }
        if (sockets.currentResult.secondsLeft < 0) {
          $("#waiting_board").hide();
          //$("#waiting_score_board").show();
        }
        if (sockets.currentResult.secondsLeft < -25) {
          alert("회차정보 오류로 인하여 결과값을 가져 올 수 없습니다.");
          clearInterval(timerId);
          try {
            window.parent.location.reload();
          } catch (e) {
            window.location.reload();
          }
        }
      } catch (e) {}
    }, 1000);
  }

  getMaxWidth() {
    if (this.state.screenWidth > this.state.screenHeight * (512 / 660)) {
      return this.state.screenHeight * (512 / 660);
    } else {
      return this.state.screenWidth;
    }
  }

  getDateTimeStr() {
    const { modal, sockets } = this.props;
    let { hourDiff, clockDiff } = sockets;
    if (clockDiff === undefined) clockDiff = 0;
    hourDiff = 9;
    let nowTime = moment.utc();
    let strDate = "";
    try {
      nowTime.add(hourDiff, 'hours');
      nowTime.add(clockDiff, 'seconds');
      strDate = nowTime.format().substring(0, 19);
      strDate = strDate.replace("T", " ");
    } catch (e) {
      nowTime = new Date();
      strDate = nowTime.toISOString().substring(0, 19);
      strDate = strDate.replace("T", " ");
    }
    return strDate;
  }

  getLeftTimeStr(seconds) {
    if (seconds < 0) seconds = 0;
    seconds = seconds / 1;
    let mins = Math.floor(seconds / 60);
    if (isNaN(mins)) mins = "-";
    let secs = Math.floor(seconds - mins * 60);
    if (isNaN(secs)) secs = "-";
    else if (secs < 10) secs = "0" + secs;
    return mins + " 분 " + secs + " 초";
  }

  getLeftTimeBarLength(seconds) {
    //return {};
    if (seconds < 0) seconds = 0;
    seconds = seconds / 1;
    return { left: 0 - ((120 - seconds) * 290) / 120 + "px" };
  }

  handleClickBtnSound(){
    const {soundOn} = this.state;
    if(soundOn === true){
        soundPlayers.readySoundPlayer.mute(true);
        soundPlayers.ballSoundPlayer.mute(true);
    }else{
        soundPlayers.readySoundPlayer.mute(false);
        soundPlayers.ballSoundPlayer.mute(false);
    }
    localStorage.setItem("soundMOn", soundOn !== true);
    this.setState({soundOn: soundOn !== true});
}

  render() {
    const { sockets } = this.props;
    const { recentResult, currentResult } = sockets;
    let { isBeingAnimation, menuFlag, soundOn, bShowResult } = this.state;
    let roundHistory = JSON.parse(JSON.stringify(recentResult));
    roundHistory = roundHistory.reverse();
    return (
      <div className="bgImg">
        <div className="topbtnBox" style={{display:(bShowResult?"block":"none")}}>
            <div className="button btn-close">
              <img
                onClick={() =>
                  this.setState({ ...this.state, bShowResult: false })
                }
                width={this.getMaxWidth() * 0.1}
                src={require("../_assets/img/score/ui_game/history/m_close.png")}
                alt=""
              />
            </div>
        </div>
        <div
          className="po_center_wrap_new inBox"
          style={{
            margin: `${this.getMaxWidth() * 0.23}px ${
              this.getMaxWidth() * 0.07
            }px ${this.getMaxWidth() * 0.07}px ${this.getMaxWidth() * 0.07}px`,
            maxWidth: `${this.getMaxWidth() * 0.86}px`,
          }}
        >
          <div className="topbtnBox">
            <div className="button btn-ranking">
              {this.state.soundOn && (
                <img
                  onClick={this.handleClickBtnSound.bind(this)}
                  src={require("../_assets/img/score/ui_game/3min-ladder/sound_on.png")}
                  width={this.getMaxWidth() * 0.12}
                  height={this.getMaxWidth() * 0.12 * 0.83}
                  alt=""
                />
              )}
              {!this.state.soundOn && (
                <img
                  onClick={this.handleClickBtnSound.bind(this)}
                  src={require("../_assets/img/score/ui_game/3min-ladder/sound_off.png")}
                  width={this.getMaxWidth() * 0.12}
                  height={this.getMaxWidth() * 0.12 * 0.83}
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="topbtnBox">
            <div className="button btn-scrap">
              <img
                onClick={() =>
                  this.setState({ ...this.state, bShowResult: true })
                }
                width={this.getMaxWidth() * 0.12}
                height={this.getMaxWidth() * 0.12 * 0.83}
                src={require("../_assets/img/score/ui_game/3min-ladder/result_b.png")}
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          className="main-container centerBox"
          style={{
            position: "absolute",
            width: `${this.getMaxWidth() * 0.8}px`,
            height: `${(this.getMaxWidth() / 512) * 660 * 0.7}px`,
            padding: 0,
            zIndex:100,
            left: `${this.getMaxWidth() * 0.1}px`,
            top: `${this.getMaxWidth() * 0.25}px`,
            transform: "none",
          }}
        >
          <p
            id="clock"
            className="po_center_data"
            style={{ color: "#ffffff", width: "100%" }}
          >
            {this.getDateTimeStr()}
          </p>

          <div className="playBox">
            <div className="relativeBox">
              <div className="pl_icon start_l"></div>
              <div className="pl_icon start_r"></div>
              <ul className="s4"></ul>
              <div className="lineBox"></div>
              <div className="pl_icon end_l"></div>
              <div className="pl_icon end_r"></div>
            </div>
          </div>
          <div className="gmImg gameResultBox"></div>
          <div className="bepickLogo">
            <div
              id="loader"
              style={{
                width: "100%",
                height: "100%",
                margin: 0,
                padding: "0 10px",
              }}
            >
              <ul
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            {/* <span className="gmImg logo"></span> */}
          </div>
          <div id="game_board" className="game_board"></div>
          <div
            id="waiting_board"
            className="waiting_board"
            style={{ display: isBeingAnimation ? "none" : "block" }}
          >
            <div className="tx">
              <div className="date_round">
                <span id="date_round"> {currentResult.round}</span>회차
              </div>
              <div className="countdown_clock">
                <span id="countdown_clock">
                  추첨 {this.getLeftTimeStr(currentResult.secondsLeft)}전
                </span>
              </div>
            </div>
            <div className="inner">
              <div
                id="timer_gauge"
                className="gauge"
                style={this.getLeftTimeBarLength(currentResult.secondsLeft)}
              ></div>
            </div>
          </div>
          <div id="waiting_score_board" className="waiting_score_board"></div>
          <div
            id="score_board"
            className="score_board __web-inspector-hide-shortcut__"
            style={{ display: "none" }}
          >
            <div className="curr_round">
              <span className="pick_num">
                <strong>-회차</strong>
              </span>
              <span className="pick_num2">-</span>
            </div>
            <div className="result_info">
              <div className="normalball"></div>
              <div className="powerball"></div>
            </div>
            <span className="result_ball"></span>
          </div>
          <dl
            id="result_board"
            className="result_board"
            style={{ display: "none" }}
          >
            <dt>회차</dt>
            <dd className="round"></dd>
            <dt>파워볼</dt>
            <dd className="powerball"></dd>
            <dt>숫자합</dt>
            <dd className="sum"></dd>
          </dl>
        </div>

        <Drawer
          direction="right"
          size={250}
          overlayOpacity={0.9}
          zIndex={5000}
          open={this.state.bShowResult}
          //onClose={() => this.setState({ ...this.state, bShowResult: false })}
          style={{ background: "transparent", boxShadow: "none", zIndex:10000 }}
        >
          <div className="bgResult">
            <div className="result-header">회차결과</div>
            <div className="body">
              {roundHistory.map((item) => {
                return (
                  <div className="item">
                    <div className="item-round">{item["pick_num2"]}회</div>
                    <div className="item-result">
                      <img
                        src={require(`../_assets/img/score/ui_game/3min-ladder/result_icon/${item.lr.toLowerCase()}_icon.png`)}
                      />
                      <img
                        src={require(`../_assets/img/score/ui_game/3min-ladder/result_icon/${item.line.toLowerCase()}_ladder_icon.png`)}
                      />
                      <img
                        src={require(`../_assets/img/score/ui_game/3min-ladder/result_icon/${item.oe.toLowerCase()}_icon.png`)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { modal, sockets, game } = state;
  return {
    modal,
    sockets,
    game,
  };
}

const connectedHomePage = connect(mapStateToProps)(GamePage);
export { connectedHomePage as MGamePage };
