import { gameConstants} from '../_constants';
import { gameService} from '../_services';

export const gameActions = {
    downLeftTime,
    fetchConfig,
};

function fetchConfig() {
  return dispatch => {
        gameService.fetchConfig()
            .then(
                resp => {
                    setTimeout(function(){
                      dispatch(success(resp.data));
                    }, 0)
                }
            );
    };
    function success(data) { return { type: gameConstants.FETCH_CONFIG, data } }
}

function downLeftTime() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: gameConstants.DOWN_LEFTTIME} }
}
