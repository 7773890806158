import React from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { GamePage, MGamePage } from "../GamePage";
import { HashRouter as Router, Routes, Route, Link } from "react-router-dom";
import { HistoryComponent } from "../GamePage/HistoryComponent";
require("../_assets/css/reset.css");
require("../_assets/css/score/ui_game/banner.css");
require("../_assets/css/score/ui_game/powerball_new.css");
require("../_assets/css/score/ui_game/common.css");
require("../_assets/css/score/ui_game/layoutc1fd.css");
require("../_assets/css/score/ui_game/room_newlist.css");

class App extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
  }

  componentWillMount() {
    const { dispatch } = this.props;
  }

  componentWillUpdate(nextProps) {}

  render() {
    return (
      <Router>
        <Routes>
        <Route
            exact
            path="/"
            element ={
                <div className="pc">
                  <div className="gmContent wrapper">
                    <GamePage />
                  </div>
                  <HistoryComponent />
                </div>
            }
          />
          <Route
            exact
            path="/pc"
            element ={
                <div className="pc">
                  <div className="gmContent wrapper">
                    <GamePage />
                  </div>
                </div>
            }
          />
          <Route
            exact
            path="/mobile"
            element ={
                <div className="mobile">
                  <div className="gmContent">
                    <MGamePage />
                  </div>
                </div>
            }
          />
        </Routes>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
