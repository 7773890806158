import { createStore, applyMiddleware } from 'redux';
import {messageTypes} from '../_constants/socket.constants';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../_reducers';
import {socketActions} from '../_actions';

const middlewares = [];

middlewares.push(thunkMiddleware);
if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`);
  const loggerMiddleware = createLogger({
    predicate: function(getState, action){
      return false;
      if(action){
        return action.type !== "GAME_DOWN_LEFTTIME" && action.type !== "ntry/grade";
      }else{
        return false;
      }
    }
  });
  middlewares.push(loggerMiddleware);
}

const store = createStore(
    rootReducer,
    applyMiddleware(...middlewares)
);
socketActions.init(store);
export {store};
