import { soundConstants } from '../_constants';

const initialState = {
  soundOn : true
};

export function sound (state = initialState, action) {
    switch (action.type) {
      case soundConstants.PLAY_WINSOUND:
        return {...state, win: true, action: action.action, level: action.level};
      case soundConstants.PLAY_LOSTSOUND:
        return {...state, win: false, action: action.action};
      case soundConstants.MUTE:
        state.soundOn = !state.soundOn;
        return {...state};
      default:
        return {...state}
    }
}
