import React, { Component } from 'react';
import { connect } from 'react-redux';
var classNames = require('classnames');

class GraphViewComponent extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){

    }

    getBarElement(val1, val2){
        if(val1 <  val2)
            return (<div className="gauge right" style={{"right": (0- val1) + "%"}}></div>);
        else
            return (<div className="gauge left" style={{"left": (0- val2) + "%"}}></div>);
    }

    render(){
        const { gradeData } = this.props.sockets;
        const {powerball} = gradeData;
        if(powerball == null) return (<div></div>);
        return (
            <div id="dist_graph_t" className="dist_graph_t">
                <dl className="item b_oe" rel="b_oe">
                    <dd className="bar">
                        {this.getBarElement(powerball['b_oe'][0], powerball['b_oe'][1])}
                    </dd>
                    <dt className="left_dt"><span className="odd">홀</span><span className="tx">{powerball['b_oe'][0]}%</span></dt>
                    <dt className="right_dt"><span className="even">짝</span><span className="tx">{powerball['b_oe'][1]}%</span></dt>
                </dl>
                <dl className="item b_ou" rel="b_ou">
                    <dd className="bar">
                        {this.getBarElement(powerball['b_ou'][0],powerball['b_ou'][1])}
                    </dd>
                    <dt className="left_dt"><span className="over">오버</span><span className="tx">{powerball['b_ou'][0]}%</span></dt>
                    <dt className="right_dt"><span className="under">언더</span><span className="tx">{powerball['b_ou'][1]}%</span>
                    </dt>
                </dl>
                <dl className="item p_oe" rel="p_oe">
                    <dd className="bar">
                        {this.getBarElement(powerball['p_oe'][0],powerball['p_oe'][1])}
                    </dd>
                    <dt className="left_dt"><span className="odd">홀</span><span className="tx">{powerball['p_oe'][0]}%</span></dt>
                    <dt className="right_dt"><span className="even">짝</span><span className="tx">{powerball['p_oe'][1]}%</span></dt>
                </dl>
                <dl className="item p_ou" rel="p_ou">
                    <dd className="bar">
                        {this.getBarElement(powerball['p_ou'][0],powerball['p_ou'][1])}
                    </dd>
                    <dt className="left_dt"><span className="over">오버</span><span className="tx">{powerball['p_ou'][0]}%</span></dt>
                    <dt className="right_dt"><span className="under">언더</span><span className="tx">{powerball['p_ou'][1]}%</span>
                    </dt>
                </dl>
                <div className="item bms" rel="b_sect">
                    <dd className="big"><span>대</span><span className="tx">{powerball['b_sect'][0]}%</span></dd>
                    <dd className="middle"><span>중</span><span className="tx">{powerball['b_sect'][1]}%</span></dd>
                    <dd className="small"><span>소</span><span className="tx">{powerball['b_sect'][2]}%</span></dd>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {  sockets } = state;
    return {
        sockets
    };
}


const connectedLoading = connect(mapStateToProps)(GraphViewComponent);
export {connectedLoading as GraphViewComponent};
